import './App.css';
import TreeMapChart from './components/statesummary';

function App() {
  return (
    <div className="App">
    
      <TreeMapChart/>
    </div>
  );
}

export default App;
